import { computed } from "vue";
import { useStorage, useCssVar } from "@vueuse/core";
import type { Partner } from "~/types/Partner";
import { PARTNER_AOA, PARTNER_TAGTHAI, PARTNER_OPENRICE } from "~/constants";

const partnerNames = [PARTNER_AOA, PARTNER_TAGTHAI, PARTNER_OPENRICE] as const;

type PartnerNames = (typeof partnerNames)[number];

const defaultPartner: Partner = {
  name: "",
  layout: "",
  token: "",
  logo: "",
  color: {
    primary: "",
    primaryDark: "",
    primaryLight: "",
    primaryPink: "",
    primaryFilter: "",
  },
  data: null,
  features: {
    enableVoucher: true,
    enableOffers: true,
    enableGiftCard: true,
    enableBreadcrumbs: true,
    enableFavorites: true,
    enableMobileNavigation: true,
    enableDelivery: true,
  },
};

function usePartner() {
  const partner = useStorage("hungryhub_partner", defaultPartner);
  const isPartner = computed(() => {
    return partnerNames.includes(partner.value.name as PartnerNames);
  });
  const layout = computed(() => {
    return partner.value.layout;
  });
  const isPartnerTagThai = computed(() => {
    return partner.value.name === PARTNER_TAGTHAI;
  });

  const primaryColor = useCssVar("--primary-color");
  primaryColor.value = partner.value?.color?.primary || "";
  const primaryDarkColor = useCssVar("--primary-color-dark");
  primaryDarkColor.value = partner.value?.color?.primaryDark || "";
  const primaryLightColor = useCssVar("--primary-color-light");
  primaryLightColor.value = partner.value?.color?.primaryLight || "";
  const primaryPinkColor = useCssVar("--primary-color-pink");
  primaryPinkColor.value = partner.value?.color?.primaryPink || "";
  const primaryFilterColor = useCssVar("--primary-color-filter");
  primaryFilterColor.value = partner.value?.color?.primaryFilter || "";

  const setPartner = (payload: Partner) => {
    partner.value = payload;
  };

  return {
    isPartnerTagThai,
    partner,
    setPartner,
    isPartner,
    layout,
  };
}

export { usePartner };
